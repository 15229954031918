<template>
  <img src="./assets/blind-watermark-logo.png" alt="BlindWatermark Logo" style="height: 300px; width: 300px">
  <WelcomePage msg="欢迎访问 空泡盲水印"/>
</template>

<script>
import WelcomePage from './components/WelcomePage.vue'

export default {
  name: 'App',
  components: {
    WelcomePage
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #45ab98;
  margin-top: 60px;
}
</style>


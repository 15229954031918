<template>
  <div>
    <h1>{{ msg }}</h1>
    <p>
      网站建设中...
    </p>
    <ul class="icp">
      <li class="li">
        <a class="a" href="https://beian.miit.gov.cn/" target="_blank" rel="noopener">粤ICP备2022075163号</a>
      </li>
      <li class="li">
        <img src="../assets/备案图标.png" alt="备案图标" style="height: 15px; width: 15px; margin-right: 2px"/>
        <a class="a" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44200002445006" target="_blank" rel="noopener">粤公网安备 44200002445006号</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'WelcomePage',
  props: {
    msg: String
  }
}
</script>

<style scoped>
.icp {
  height: 40px;
  line-height: 70px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  font-family: Arial, serif;
  font-size: 12px;
  letter-spacing: 1px;
}

.icp .li {
  display: inline-block;
  margin: 0 5px;
}

.icp .li .a {
  color: #7a7a7a50;
}
</style>
